import * as React from "react";
import {gs} from "../../theme";
import {Grid, Typography} from "@mui/material";
import {Answer, Question} from "../../domain/types";
import {AnswerInput} from "./AnswerInput";
import {useCallback} from "react";
import {MultiLingualTextDisplay} from "../../base/displays/MultiLingualTextDisplay";

interface AnswerQuestionFormProps {
    question: Question;
    answer?: Answer;
    onAnswer: (answer: Answer) => void;
}

export function AnswerQuestionForm(props: AnswerQuestionFormProps) {
    const {question, answer, onAnswer} = props;

    const handleAnswer = useCallback((value: string) => {
        onAnswer({ questionUuid: question.uuid, answer: value });
    }, [question]);

    return (
        <Grid container spacing={gs}>
            {question.titles.length > 0 &&
                <Grid item xs={12}>
                    <Typography variant="h6">
                        <MultiLingualTextDisplay texts={question.titles} />
                    </Typography>
                </Grid>
            }
            {question.descriptions.length > 0 &&
                <Grid item xs={12}>
                    <Typography variant="body1">
                        <MultiLingualTextDisplay texts={question.descriptions} />
                    </Typography>
                </Grid>
            }
            <Grid item xs={12}>
                <AnswerInput question={question} value={answer ? answer.answer : ""} onChange={(value) => handleAnswer(value)} />
            </Grid>
        </Grid>
    );
}
