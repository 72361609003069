import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Container from "@mui/material/Container";
import {Button, Grid} from "@mui/material";
import {gs} from "../../theme";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {useAuthContext} from "../../context/auth/AuthContext";
import {AssetsProvider} from "../../domain/AssetsProvider";
import {IAsset} from "../../domain/types";
import {MetaDataDisplay} from "../../base/data/MetaDataDisplay";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {AHeader} from "../../base/layout/AHeader";
import {AssetRisksBlock} from "./view/AssetRisksBlock";
import {AssetTasksBlock} from "./view/AssetTasksBlock";
import {AssetMeasuresBlock} from "./view/AssetMeasuresBlock";
import {AssetDetails} from "./view/AssetDetails";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {AssetRisksChart} from "./view/AssetRisksChart";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface AssetViewProps {}

export function AssetView(props: AssetViewProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const {t} = useLanguageContext();

    const [asset, setAsset] = useState<IAsset>();

    useEffect(() => {
        if (routeParams.assetUuid && api.auth) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.get(tenantUuid, routeParams.assetUuid).then(setAsset);
        }
    }, [api, tenantUuid, routeParams]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={asset ? asset.name : "..."}
                        crumbs={[
                            {label: t("assets.plural"), href: `/tenants/${tenantUuid}/assets`},
                            {
                                label: asset ? asset.name : "...",
                                href: asset ? `/tenants/${tenantUuid}/assets/${asset.uuid}` : "/assets"
                            }
                        ]}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => navigate(`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/edit`)}
                        >
                            {t("edit")}
                        </Button>
                    </AHeaderWithBreadcrumbs>
                </Grid>
                {asset && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <BoxedPaper>
                                <AssetDetails asset={asset} />
                            </BoxedPaper>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AssetRisksChart asset={asset} />
                        </Grid>
                        <Grid item xs={12}>
                            <AssetRisksBlock asset={asset} />
                        </Grid>
                        <Grid item xs={12}>
                            <AssetMeasuresBlock asset={asset} />
                        </Grid>
                        <Grid item xs={12}>
                            <AssetTasksBlock asset={asset} />
                        </Grid>
                        <Grid item xs={12}>
                            <AHeader title={t("assets.header")} variant="h2"></AHeader>
                        </Grid>
                        <Grid item xs={12}>
                            <BoxedPaper>
                                <MetaDataDisplay metaData={asset.metaData} />
                            </BoxedPaper>
                        </Grid>
                    </>
                )}
            </Grid>
        </Container>
    );
}
