import { Grid } from "@mui/material";
import * as React from "react";
import {PropsWithChildren} from "react";
import {LabeledData} from "./LabeledData";

interface LabeledItemProps extends PropsWithChildren<{
    label: string;
}>{ }

export function LabeledItem(props: LabeledItemProps) {
    const {label, children} = props;

    return (
        <Grid item>
            <LabeledData label={label}>{children}</LabeledData>
        </Grid>
    );
}
