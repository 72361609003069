import {Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import * as React from "react";
import {useCallback, useState} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface ConfirmButtonProps extends Omit<ButtonProps, "onClick"> {
    onConfirm: () => void;
    title: string;
    message: string;
    confirmButtonText?: string;
}

export function ConfirmButton(props: ConfirmButtonProps) {
    const {onConfirm, title, message, confirmButtonText, ...buttonProps} = props;
    const {t} = useLanguageContext();

    const [open, setOpen] = useState<boolean>(false);

    const handleConfirm = useCallback(() => {
        setOpen(false);
        onConfirm();
    }, [onConfirm]);

    return (
        <>
            <Button {...buttonProps} onClick={() => setOpen(true)}>
                {buttonProps.children}
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{t("cancel")}</Button>
                    <Button onClick={handleConfirm}>{confirmButtonText || t("confirm")}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
