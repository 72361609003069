import {Tune} from "@mui/icons-material";
import {Button, Grid, IconButton} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useCallback, useState} from "react";
import {AiPostureRadarChart} from "../../charts/AiPostureRadarChart";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {randomAiPosture} from "../../domain/aiPosture";
import {ArtiligenceSettings} from "../../domain/artiligenceSettings";
import ProTip from "./ProTip";
import {TuneDialog} from "./TuneDialog";

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://artiligence.at">
                Artiligence GmbH
            </Link>
            {new Date().getFullYear()}.
        </Typography>
    );
}

export default function Demo() {
    const [settings, setSettings] = useState<ArtiligenceSettings>(new ArtiligenceSettings());

    const [safeRange, setSafeRange] = useState(settings.safeRange);
    const [dangerRange, setDangerRange] = useState(settings.dangerRange);

    const [tune, setTune] = useState(false);

    const {t} = useLanguageContext();

    const handleSafeRangeChange = useCallback(
        (value: number) => {
            setSafeRange(value);
            settings.safeRange = value;
        },
        [settings]
    );

    const handleDangerRangeChange = useCallback(
        (value: number) => {
            setDangerRange(value);
            settings.dangerRange = value;
        },
        [settings]
    );

    return (
        <Container maxWidth="lg">
            <Box sx={{my: 4}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item sx={{flexGrow: 1}}>
                                <Typography variant="h4" component="h1" gutterBottom>
                                    Artiligence Demo
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <Button variant="contained" color="primary" href="https://artiligence.at">
                                            {t("addMoreData")}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={() => setTune(true)}>
                                            <Tune />
                                        </IconButton>
                                        <TuneDialog
                                            open={tune}
                                            safeRange={safeRange}
                                            dangerRange={dangerRange}
                                            onSafeRangeChange={handleSafeRangeChange}
                                            onDangerRangeChange={handleDangerRangeChange}
                                            onClose={() => setTune(false)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <AiPostureRadarChart
                            postures={[randomAiPosture("test", [40, 75, 114])]}
                            safeRange={safeRange}
                            dangerRange={dangerRange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ProTip />
                    </Grid>
                    <Grid item xs={12}>
                        <Copyright />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}
