import * as React from "react";
import {gs} from "../../theme";
import {Button, Divider, Grid, Paper, Typography} from "@mui/material";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import Container from "@mui/material/Container";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {useNavigate} from "react-router-dom";
import {AllAssetRiskChartMode, AllAssetRisksChart} from "./asset-risks/AllAssetRisksChart";
import {EnumSelectInput} from "../../base/inputs/EnumSelectInput";
import {useEffect, useState} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useAuthContext} from "../../context/auth/AuthContext";
import {IAssetRisk, IMeasure} from "../../domain/types";
import {MeasuresList} from "./MeasuresList";
import {MeasuresProvider} from "../../domain/MeasuresProvider";
import {AHeader} from "../../base/layout/AHeader";
import {DashboardProvider} from "../../domain/DashboardProvider";
import {DashboardStats} from "../../domain/dashboard";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {KpiDisplay} from "../../base/displays/KpiDisplay";
import {RisksProvider} from "../../domain/RisksProvider";
import {RisksList} from "./RisksList";

interface DashboardProps {}

export function Dashboard(props: DashboardProps) {
    const {api} = useAuthContext();
    const {tenantUuid, tenant} = useTenantContext();
    const navigate = useNavigate();

    const [mode, setMode] = useState(AllAssetRiskChartMode.WorstCase);
    const [top10Measures, setTop10Measures] = useState<IMeasure[]>();
    const [top10Risks, setTop10Risks] = useState<IAssetRisk[]>();
    const [stats, setStats] = useState<DashboardStats>();

    useEffect(() => {
        if (api && api.auth) {
            const measuresProvider = new MeasuresProvider(api);
            measuresProvider.topTen(tenantUuid).then(setTop10Measures);
            const risksProvider = new RisksProvider(api);
            risksProvider.topTen(tenantUuid).then(setTop10Risks);
            const dashboardProvider = new DashboardProvider(api);
            dashboardProvider.stats(tenantUuid).then(setStats);
        }
    }, [api, tenantUuid]);

    const {t} = useLanguageContext();
    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={tenant ? tenant.name : "..."}
                        crumbs={[{label: t("dashboard.singular"), href: `/tenants/${tenantUuid}`}]}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grid container spacing={gs}>
                        <Grid item xs={12}>
                            <AHeader title={t("dashboard.top10Measures")} variant="h2" />
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                {top10Measures &&
                                    <MeasuresList measures={top10Measures} />
                                }
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <AHeader title={t("dashboard.top10Risks")} variant="h2" />
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                {top10Risks &&
                                    <RisksList risks={top10Risks} />
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Grid container spacing={gs}>
                        <Grid item xs={12}>
                            <AHeader title={t("dashboard.riskOverview")} variant="h2">
                                <EnumSelectInput
                                    label={t("dashboard.labels.mode")}
                                    lookupKey={"dashboard.chartModes"}
                                    options={Object.keys(AllAssetRiskChartMode)}
                                    value={mode}
                                    onChange={setMode}
                                    small
                                />
                            </AHeader>
                        </Grid>
                        <Grid item xs={12}>
                            <AllAssetRisksChart mode={mode} />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <AHeader title={t("dashboard.assetOverview.title")} variant="h2" />
                        </Grid>
                        <Grid item xs={12}>
                            {stats &&
                                <BoxedPaper>
                                    <Grid container spacing={gs}>
                                        <Grid item>
                                            <KpiDisplay title={t("assets.plural")}>
                                                <Typography variant="h6">{stats.assets}</Typography>
                                            </KpiDisplay>
                                        </Grid>
                                        <Grid item>
                                            <KpiDisplay title={t("tools.plural")}>
                                                <Typography variant="h6">{stats.tools}</Typography>
                                            </KpiDisplay>
                                        </Grid>
                                        <Grid item>
                                            <KpiDisplay title={t("risks.plural")}>
                                                <Typography variant="h6">{stats.risks}</Typography>
                                            </KpiDisplay>
                                        </Grid>
                                        <Grid item>
                                            <KpiDisplay title={t("measures.plural")}>
                                                <Typography variant="h6">{stats.measures}</Typography>
                                            </KpiDisplay>
                                        </Grid>
                                    </Grid>
                                </BoxedPaper>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
