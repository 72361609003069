import {Button, Grid} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import * as React from "react";
import {useEffect, useState} from "react";
import {AHeader} from "../../../base/layout/AHeader";
import {ATable} from "../../../base/table/ATable";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import {IAsset, ITask} from "../../../domain/types";
import {gs} from "../../../theme";

interface AssetTasksBlockProps {
    asset: IAsset;
}

export function AssetTasksBlock(props: AssetTasksBlockProps) {
    const {asset} = props;
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const {t} = useLanguageContext();

    const [tasks, setTasks] = useState<ITask[]>();
    const [selected, setSelected] = useState<string[]>([]);

    const columns: GridColDef[] = [
        {
            field: t("assets.headerFields.title"),
            headerName: t("assets.headerNames.title"),
            flex: 1,
            valueGetter: (params) => params.row.titles.map((t: any) => t.text).join(", ")
        },
        {field: t("assets.headerFields.category"), headerName: t("assets.headerNames.category"), flex: 1},
        {field: t("assets.headerFields.priority"), headerName: t("assets.headerNames.priority"), flex: 1},
        {field: t("assets.headerFields.state"), headerName: t("assets.headerNames.state"), flex: 1}
    ];

    useEffect(() => {
        if (api.auth) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.tasks(tenantUuid, asset.uuid).then(setTasks);
        }
    }, [api, tenantUuid, asset]);

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <AHeader title={t("tasks.plural")} variant="h2">
                    <Button>{t("tasks.addTask")}</Button>
                </AHeader>
            </Grid>
            {tasks && (
                <Grid item xs={12}>
                    <ATable rows={tasks} columns={columns} onSelectionChanged={setSelected} />
                </Grid>
            )}
        </Grid>
    );
}
