import * as React from "react";
import {MeasureState} from "../../domain/types";
import {EnumSelectInput} from "./EnumSelectInput";

interface MeasureStateInputProps {
    label: string;
    value: MeasureState;
    onChange: (value: MeasureState) => void;
    disabled?: boolean;
}

export function MeasureStateInput(props: MeasureStateInputProps) {
    const {label, value, onChange, disabled} = props;

    return (
        <EnumSelectInput
            label={label}
            lookupKey="measures.labels"
            options={[MeasureState.Identified, MeasureState.InProgress, MeasureState.Completed]}
            value={value}
            onChange={onChange}
        />
    );
}
