import * as React from "react";
import {Divider, Grid, Typography} from "@mui/material";
import {gs} from "../../../theme";
import {TextInput} from "../../../base/inputs/TextInput";
import {useLanguageContext} from "../../../context/language/LanguageContext";

interface StepNameInputProps {
    name: string;
    onNameChanged: (name: string) => void;
}

export function StepNameInput(props: StepNameInputProps) {
    const {name, onNameChanged} = props;
    const {t} = useLanguageContext();
    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <TextInput label={t("name")} value={name} onChange={onNameChanged} />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">{t("tools.typography.differentToolName")}</Typography>
            </Grid>
        </Grid>
    );
}
