import * as React from "react";
import {useCallback} from "react";
import {MenuItem, TextField} from "@mui/material";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface EnumSelectInputProps<T> {
    label: string;
    options: T[] | string[];
    lookupKey?: string;
    value: T;
    onChange: (value: T) => void;
    disabled?: boolean;
    allowNone?: boolean;
    small?: boolean;
}

export function EnumSelectInput<T>(props: EnumSelectInputProps<T>) {
    const {label, options, lookupKey, value, onChange, disabled, allowNone, small} = props;
    const {t, es} = useLanguageContext();

    const handleChanged = useCallback(
        (value: unknown) => {
            onChange(value as T);
        },
        [onChange]
    );

    return (
        <TextField
            label={label}
            variant="outlined"
            fullWidth
            select
            size={small ? "small" : undefined}
            value={value}
            disabled={disabled}
            onChange={(event) => handleChanged(event.target.value)}
        >
            {allowNone && <MenuItem value={undefined}>{t("none")}</MenuItem>}
            {options
                .map((v) => v as unknown as string)
                .map((v) => (
                    <MenuItem key={v} value={v}>
                        {lookupKey ? es(lookupKey, v) : v}
                    </MenuItem>
                ))}
        </TextField>
    );
}
