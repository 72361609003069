import * as React from "react";
import {createElement, PropsWithChildren} from "react";
import {Grid} from "@mui/material";


interface AGridItemGrowProps extends PropsWithChildren<any> {
}

export function AGridItemGrow({children}: AGridItemGrowProps) {
    return (
        <Grid item style={{flexGrow: 1}}>{children}</Grid>
    );
}
