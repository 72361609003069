import {AuthConfiguration} from "../domain/auth";
import {appBaseUrl} from "./tools";

export class AuthFlow {

    readonly authConfiguration: AuthConfiguration;

    constructor(authConfiguration: AuthConfiguration) {
        this.authConfiguration = authConfiguration;
    }

    get redirectUri(): string {
        return `${appBaseUrl()}/auth/process-token`;
    }

    get idpLoginUrl(): string {
        return `https://${this.authConfiguration.hostedUiDomain}/login?client_id=${this.authConfiguration.clientId}&response_type=code&scope=phone+openid+email+aws.cognito.signin.user.admin+profile&redirect_uri=${this.redirectUri}`;
    }

}