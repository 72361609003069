import {Api} from "../libs/Api";
import {IMeasure} from "./types";

export class MeasuresProvider {

    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async topTen(tenantUuid: string): Promise<IMeasure[]> {
        const url = `/api/v1/tenants/${tenantUuid}/measures/top-ten`;
        return this.api.get<IMeasure[]>(url);
    }

}