import * as React from "react";
import {Chip} from "@mui/material";
import {green, orange, red} from "@mui/material/colors";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface ProbabilityDisplayProps {
    probability: number;
}

export function ProbabilityDisplay(props: ProbabilityDisplayProps) {
    const {probability} = props;
    const {t} = useLanguageContext();
    let label = t("probability.probabilities.Medium");
    let backgroundColor = undefined;
    let color = undefined;
    switch (probability) {
        case 1:
            label = t("probability.probabilities.VeryLow");
            backgroundColor = green[600];
            color = "white";
            break;
        case 2:
            label = t("probability.probabilities.Low");
            backgroundColor = green[300];
            break;
        case 3:
            label = t("probability.probabilities.Medium");
            backgroundColor = orange[300];
            break;
        case 4:
            label = t("probability.probabilities.High");
            backgroundColor = red[300];
            break;
        case 5:
            label = t("probability.probabilities.VeryHigh");
            backgroundColor = red[600];
            color = "white";
            break;
    }

    return <Chip label={label} sx={{backgroundColor, color}} />;
}
