import * as React from "react";
import {PropsWithChildren} from "react";
import Typography from "@mui/material/Typography";

interface LabeledDataProps extends PropsWithChildren<{
    label: string;
}>{ }

export function LabeledData(props: LabeledDataProps) {
    const {label, children} = props;

    return (
        <div>
            <Typography variant="overline">{label}</Typography>
            <div>{children}</div>
        </div>
    );
}
