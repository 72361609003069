import {Chip} from "@mui/material";
import {green, orange, red} from "@mui/material/colors";
import * as React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface TotalRiskDisplayProps {
    totalRisk: number;
}

export function TotalRiskDisplay(props: TotalRiskDisplayProps) {
    const {totalRisk} = props;
    const {t} = useLanguageContext();

    let label = t("totalRisks.totalRisks.Medium");
    let backgroundColor = undefined;
    let color = undefined;

    if (totalRisk >= 1 && totalRisk <= 5) {
        label = t("totalRisks.totalRisks.VeryLow");
        backgroundColor = green[700];
        color = "white";
    } else if (totalRisk > 5 && totalRisk <= 8) {
        label = t("totalRisks.totalRisks.Low");
        backgroundColor = green[300];
    } else if (totalRisk > 8 && totalRisk <= 15) {
        label = t("totalRisks.totalRisks.Medium");
        backgroundColor = orange[300];
    } else if (totalRisk > 15 && totalRisk <= 20) {
        label = t("totalRisks.totalRisks.High");
        backgroundColor = red[300];
    } else {
        label = t("totalRisks.totalRisks.VeryHigh");
        backgroundColor = red[600];
        color = "white";
    }

    return <Chip label={`${totalRisk}: ${label}`} sx={{backgroundColor, color}} />;
}
