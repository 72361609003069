import * as React from "react";
import {LinearProgress, Paper} from "@mui/material";
import Box from "@mui/material/Box";
import {PropsWithChildren} from "react";

interface BoxedPaperProps extends PropsWithChildren<{
    padding?: number;
    loading?: boolean;
}> {}

export function BoxedPaper(props: BoxedPaperProps) {
    const {padding, loading, children} = props;
    return (
        <Paper>
            <Box p={padding || 2}>
                {children}
            </Box>
            {loading && <LinearProgress />}
        </Paper>
    );
}
