import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useCallback, useEffect, useMemo, useState} from "react";
import Container from "@mui/material/Container";
import {Button, Grid} from "@mui/material";
import {gs} from "../../theme";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {useAuthContext} from "../../context/auth/AuthContext";
import {AssetsProvider} from "../../domain/AssetsProvider";
import {AssetModification, IAsset} from "../../domain/types";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {AssetForm} from "./edit/AssetForm";
import {ConfirmDeleteButton} from "../../base/buttons/ConfirmDeleteButton";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface AssetEditProps {}

export function AssetEdit(props: AssetEditProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();

    const [asset, setAsset] = useState<AssetModification>();
    const [submitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (routeParams.assetUuid && api.auth) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.get(tenantUuid, routeParams.assetUuid).then(setAsset);
        }
    }, [api, tenantUuid, routeParams]);

    const canSave = useMemo<boolean>(
        () =>
            Boolean(
                asset &&
                    asset.name &&
                    asset.type &&
                    asset.category &&
                    asset.visibility &&
                    asset.descriptions.length > 0 &&
                    asset.vendor
            ),
        [asset]
    );

    const handleSaveAsset = useCallback(() => {
        if (asset && canSave && routeParams.assetUuid && api.auth) {
            setSubmitting(true);
            const assetProvider = new AssetsProvider(api);
            assetProvider
                .modify(tenantUuid, routeParams.assetUuid, asset)
                .then(() => {
                    setSubmitting(false);
                    navigate(`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    }, [routeParams, canSave, asset, api, tenantUuid, navigate]);

    const handleDeleteAsset = useCallback(() => {
        if (asset && routeParams.assetUuid && api.auth) {
            setSubmitting(true);
            const assetProvider = new AssetsProvider(api);
            assetProvider
                .delete(tenantUuid, routeParams.assetUuid)
                .then(() => {
                    setSubmitting(false);
                    navigate(`/tenants/${tenantUuid}/assets`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    }, [routeParams, asset, api, tenantUuid, navigate]);

    const {t} = useLanguageContext();
    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={asset ? asset.name : "..."}
                        crumbs={[
                            {label: t("assets.plural"), href: `/tenants/${tenantUuid}/assets`},
                            {
                                label: asset ? asset.name : "...",
                                href: asset ? `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}` : "/assets"
                            },
                            {
                                label: t("edit"),
                                href: asset ? `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/edit` : "/assets"
                            }
                        ]}
                    ></AHeaderWithBreadcrumbs>
                </Grid>
                {asset && (
                    <Grid item xs={12}>
                        <BoxedPaper loading={submitting}>
                            <Grid container spacing={gs}>
                                <Grid item xs={12}>
                                    <AssetForm asset={asset} onChange={setAsset} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={gs}>
                                            <Grid item>
                                                <Button
                                                    onClick={handleSaveAsset}
                                                    color="primary"
                                                    variant="contained"
                                                    disabled={!canSave || submitting}
                                                >
                                                    {t("save")}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <ConfirmDeleteButton
                                                    onConfirm={handleDeleteAsset}
                                                    title={t("assets.deleteAsset")}
                                                    message={`${t("assets.deleteConfirmationMessage")} ${asset.name}?`}
                                                >
                                                    {t("delete")}
                                                </ConfirmDeleteButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </BoxedPaper>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
