import * as React from "react";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {useEffect, useMemo, useState} from "react";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import {IAsset, IAssetRisk, RiskCategory} from "../../../domain/types";
import {Paper} from "@mui/material";
import {AiDimensionValue, AiPosture} from "../../../domain/aiPosture";
import {ArtiligenceSettings} from "../../../domain/artiligenceSettings";
import {AiPostureRadarChart} from "../../../charts/AiPostureRadarChart";
import {gridDateFormatter} from "@mui/x-data-grid";
import {randomBlue} from "../../../libs/tools";

export enum AllAssetRiskChartMode {
    WorstCase = "WorstCase",
    Average = "Average",
    All = "All"
}

interface AllAssetRisksChartProps {
    mode: AllAssetRiskChartMode;
}

interface AssetWithRisks {
    asset: IAsset;
    risks: IAssetRisk[];
}

export function AllAssetRisksChart(props: AllAssetRisksChartProps) {
    const {mode} = props;
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const [settings, setSettings] = useState<ArtiligenceSettings>(new ArtiligenceSettings());

    const [safeRange, setSafeRange] = useState(settings.safeRange);
    const [dangerRange, setDangerRange] = useState(settings.dangerRange);

    const [assets, setAssets] = useState<IAsset[]>();
    const [risks, setRisks] = useState<IAssetRisk[][]>();

    useEffect(() => {
        const assetsProvider = new AssetsProvider(api);
        if(api.auth) {
            assetsProvider.list(tenantUuid, {page: 0, pageSize: 1000}).then((page) => {
                setAssets(page.content);
                const promises: Promise<IAssetRisk[]>[] = [];
                for (let asset of page.content) {
                    promises.push(assetsProvider.risks(tenantUuid, asset.uuid));
                }
                Promise.all(promises).then(setRisks);
            });
        }
    }, [api, tenantUuid]);

    const filtered = useMemo<AssetWithRisks[]>(() => {
        if(assets && risks) {
            const result: AssetWithRisks[] = [];
            for(let asset of assets) {
                const filteredRisks = risks.filter((r) => r.length > 0 && r[0].asset.uuid === asset.uuid);
                if(filteredRisks && filteredRisks.length > 0) {
                    result.push({
                        asset,
                        risks: filteredRisks[0]
                    });
                }
            }
            return result;
        }
        return [];
    }, [assets, risks]);

    const postures = useMemo<AiPosture[]>(() => {
        switch (mode) {
            case AllAssetRiskChartMode.All:
                const postures: AiPosture[] = [];
                for(let assetWithRisks of filtered) {
                    switch (mode) {
                        case AllAssetRiskChartMode.All:
                            const values: AiDimensionValue[] = [];
                            for(let category of Object.keys(RiskCategory)) {
                                const risk = assetWithRisks.risks.find((r) => r.category === category);
                                values.push({
                                    dimension: category as RiskCategory,
                                    value: risk ? risk.risk.probability * risk.risk.severity : 0
                                });
                            }
                            postures.push({
                                id: assetWithRisks.asset.uuid,
                                label: assetWithRisks.asset.name,
                                color: randomBlue(),
                                values
                            });
                            break;
                    }
                }
                return postures;
            case AllAssetRiskChartMode.WorstCase:
            case AllAssetRiskChartMode.Average:
                const values: AiDimensionValue[] = [];
                for (let category of Object.keys(RiskCategory)) {
                    values.push({
                        dimension: category as RiskCategory,
                        value: 0
                    });
                }
                for (let assetWithRisks of filtered) {
                    for (let risk of assetWithRisks.risks) {
                        const existing = values.find((v) => v.dimension === risk.category);
                        if (existing) {
                            const current = risk.risk.probability * risk.risk.severity;
                            if (mode == AllAssetRiskChartMode.WorstCase) {
                                if(current > existing.value) {
                                    existing.value = current;
                                }
                            } else if(mode == AllAssetRiskChartMode.Average) {
                                existing.value += current;
                            }
                        } else {
                            values.push({
                                dimension: risk.category,
                                value: risk.risk.probability * risk.risk.severity
                            });
                        }
                    }
                }
                if (mode == AllAssetRiskChartMode.Average) {
                    for(let value of values) {
                        value.value = value.value / filtered.length;
                    }
                }
                return [{
                    id: mode,
                    label: mode,
                    color: [40, 75, 114],
                    values
                }];
            default:
                return [];
        }
    }, [mode, filtered]);

    if(postures.length == 0) {
        return null;
    }

    return (
        <Paper>
            <AiPostureRadarChart postures={postures} safeRange={safeRange} dangerRange={dangerRange} />
        </Paper>
    );
}
