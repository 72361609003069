import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useCallback, useEffect, useMemo, useState} from "react";
import {ToolModification} from "../../domain/types";
import {KiToolsProvider} from "../../domain/KiToolsProvider";
import {Button, Container, Grid} from "@mui/material";
import {gs} from "../../theme";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {ConfirmDeleteButton} from "../../base/buttons/ConfirmDeleteButton";
import {ToolForm} from "./edit/ToolForm";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface ToolEditProps {}

export function ToolEdit(props: ToolEditProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();

    const [tool, setTool] = useState<ToolModification>();
    const [submitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (routeParams.kiToolUuid && api.auth) {
            const kiToolsProvider = new KiToolsProvider(api);
            kiToolsProvider.get(tenantUuid, routeParams.kiToolUuid).then(setTool);
        }
    }, [api, routeParams]);

    const canSave = useMemo<boolean>(
        () =>
            Boolean(
                tool &&
                    tool.name &&
                    tool.type &&
                    tool.category &&
                    tool.visibility &&
                    tool.descriptions.length > 0 &&
                    tool.vendor
            ),
        [tool]
    );

    const handleSaveTool = useCallback(() => {
        if (tool && canSave && routeParams.kiToolUuid && api.auth) {
            setSubmitting(true);
            const toolsProvider = new KiToolsProvider(api);
            toolsProvider
                .modify(tenantUuid, routeParams.kiToolUuid, tool)
                .then(() => {
                    setSubmitting(false);
                    navigate(`/tenants/${tenantUuid}/tools/${routeParams.kiToolUuid}`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    }, [routeParams, canSave, tool, api, tenantUuid, navigate]);

    const handleDeleteTool = useCallback(() => {
        if (tool && routeParams.kiToolUuid && api.auth) {
            setSubmitting(true);
            const toolsProvider = new KiToolsProvider(api);
            toolsProvider
                .delete(tenantUuid, routeParams.kiToolUuid)
                .then(() => {
                    setSubmitting(false);
                    navigate(`/tenants/${tenantUuid}/tools`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    }, [routeParams, tool, api, tenantUuid, navigate]);

    const {t} = useLanguageContext();
    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={tool ? tool.name : "..."}
                        crumbs={[
                            {
                                label: t("tools.plural"),
                                href: `/tenants/${tenantUuid}/tools`
                            },
                            {
                                label: tool ? tool.name : "...",
                                href: `/tenants/${tenantUuid}/tools/${routeParams.kiToolUuid}`
                            },
                            {
                                label: t("tools.edit"),
                                href: `/tenants/${tenantUuid}/tools/${routeParams.kiToolUuid}/edit`
                            }
                        ]}
                    ></AHeaderWithBreadcrumbs>
                </Grid>
                {tool && (
                    <Grid item xs={12}>
                        <BoxedPaper loading={submitting}>
                            <Grid container spacing={gs}>
                                <Grid item xs={12}>
                                    <ToolForm tool={tool} onChange={setTool} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={gs}>
                                            <Grid item>
                                                <Button
                                                    onClick={handleSaveTool}
                                                    color="primary"
                                                    variant="contained"
                                                    disabled={!canSave || submitting}
                                                >
                                                    {t("tools.save")}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <ConfirmDeleteButton
                                                    onConfirm={handleDeleteTool}
                                                    title={t("tools.deleteTitle")}
                                                    message={`${t("tools.deleteMessage")} ${tool.name}?`}
                                                >
                                                    {t("tools.delete")}
                                                </ConfirmDeleteButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </BoxedPaper>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
