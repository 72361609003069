import {Api} from "../libs/Api";
import {Page, PageChange, Role, RoleCreation, RoleModification} from "./types";

export class RolesProvider {
    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async list(tenantUuid: string, pageChange: PageChange): Promise<Page<Role>> {
        const url = this.api.appendPagingParams(`/api/v1/tenants/${tenantUuid}/roles`, pageChange);
        return this.api.get<Page<Role>>(url);
    }

    async get(tenantUuid: string, roleUuid: string) {
        const url = `/api/v1/tenants/${tenantUuid}/roles/${roleUuid}`;
        return this.api.get<Role>(url);
    }

    async create(tenantUuid: string, role: RoleCreation) {
        const url = `/api/v1/tenants/${tenantUuid}/roles`;
        return this.api.post<Role>(url, role);
    }

    async modify(tenantUuid: string, roleUuid: string, role: RoleModification) {
        const url = `/api/v1/tenants/${tenantUuid}/roles/${roleUuid}`;
        return this.api.put<Role>(url, role);
    }

    async delete(tenantUuid: string, roleUuid: string) {
        const url = `/api/v1/tenants/${tenantUuid}/roles/${roleUuid}`;
        return this.api.delete(url);
    }

}