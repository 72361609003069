import {Grid} from "@mui/material";
import * as React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {MetaData} from "../../domain/types";
import {gs} from "../../theme";
import {UserDisplay} from "../displays/UserDisplay";
import {LabeledItem} from "./LabeledItem";

interface MetaDataDisplayProps {
    metaData: MetaData;
}

export function MetaDataDisplay(props: MetaDataDisplayProps) {
    const {metaData} = props;
    const {t} = useLanguageContext();
    return (
        <Grid container spacing={gs}>
            <LabeledItem label={t("metaData.createdBy")}>
                <UserDisplay username={metaData.createdBy} />
            </LabeledItem>
            <LabeledItem label={t("metaData.createdAt")}>{metaData.createdAt}</LabeledItem>
            <LabeledItem label={t("metaData.updatedBy")}>
                <UserDisplay username={metaData.updatedBy} />
            </LabeledItem>
            <LabeledItem label={t("metaData.updatedAt")}>{metaData.updatedAt}</LabeledItem>
        </Grid>
    );
}
