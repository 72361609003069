import * as React from "react";
import {useCallback} from "react";
import {AnswerType, Question} from "../../domain/types";
import {BooleanInput} from "../../base/inputs/BooleanInput";
import {MultipleChoiceMultipleSelectInput} from "./MultipleChoiceMultipleSelectInput";
import {MultipleChoiceSingleAnswerInput} from "./MultipleChoiceSingleAnswerInput";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface AnswerInputProps {
    question: Question;
    value: string;
    onChange: (value: string) => void;
}

export function AnswerInput(props: AnswerInputProps) {
    const {question, value, onChange} = props;
    const {t} = useLanguageContext();
    const handleBooleanChanged = useCallback(
        (value: boolean) => {
            onChange(value ? t("yes") : t("no"));
        },
        [onChange]
    );

    switch (question.type) {
        case AnswerType.MultipleChoiceSingleAnswer:
            return <MultipleChoiceSingleAnswerInput question={question} value={value} onChange={onChange} />;

        case AnswerType.MultipleChoiceMultipleAnswers:
            return <MultipleChoiceMultipleSelectInput question={question} value={value} onChange={onChange} />;
        case AnswerType.Boolean:
            return <BooleanInput value={value ? t("yes") == value : undefined} onChange={handleBooleanChanged} />;
        default:
            throw new Error(`AnswerInput not implemented for AnswerType: ${question.type}`);
    }
}
