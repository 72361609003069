
interface SettingsData {
    safeRange: number;
    dangerRange: number;
}

export class ArtiligenceSettings {

    private settingsData: SettingsData;

    constructor() {
        const value = localStorage.getItem("artiligence");
        if(value) {
            this.settingsData = JSON.parse(value);
        }else {
            this.settingsData = {
                safeRange: 8,
                dangerRange: 15
            };
        }
    }

    get safeRange(): number {
        return this.settingsData.safeRange;
    }

    set safeRange(value: number) {
        this.settingsData.safeRange = value;
        localStorage.setItem("artiligence", JSON.stringify(this.settingsData));
    }

    get dangerRange(): number {
        return this.settingsData.dangerRange;
    }

    set dangerRange(value: number) {
        this.settingsData.dangerRange = value;
        localStorage.setItem("artiligence", JSON.stringify(this.settingsData));
    }

}