import { Grid } from "@mui/material";
import * as React from "react";
import {PropsWithChildren} from "react";
import {ABreadcrumbs, ACrumb} from "./ABreadcrumbs";
import {gs} from "../../theme";
import {APageHeader} from "./APageHeader";
import Box from "@mui/material/Box";

interface AHeaderWithBreadcrumbsProps extends PropsWithChildren<{
    title: string;
    crumbs: ACrumb[];
}> {}

export function AHeaderWithBreadcrumbs(props: AHeaderWithBreadcrumbsProps) {
    const {title, crumbs, children} = props;

    return (
        <Box paddingTop={gs}>
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <ABreadcrumbs crumbs={crumbs} />
                </Grid>
                <Grid item xs={12}>
                    <APageHeader title={title}>
                        {children}
                    </APageHeader>
                </Grid>
            </Grid>
        </Box>
    );
}
