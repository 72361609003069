import {MenuItem, TextField} from "@mui/material";
import * as React from "react";
import {useCallback, useMemo} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface BooleanInput {
    label?: string;
    value?: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
}

export function BooleanInput(props: BooleanInput) {
    const {label, value, onChange, disabled} = props;
    const {t} = useLanguageContext();
    const handleChange = useCallback(
        (value: string) => {
            onChange("Yes" == value);
        },
        [onChange]
    );

    const stringValue = useMemo<string>(() => {
        if (value === undefined) {
            return "";
        }
        return value ? "Yes" : "No";
    }, [value]);

    return (
        <TextField
            label={label}
            variant="outlined"
            fullWidth
            select
            value={stringValue}
            disabled={disabled}
            onChange={(event) => handleChange(event.target.value)}
        >
            <MenuItem key="Yes" value="Yes">
                {t("yes")}
            </MenuItem>
            <MenuItem key="No" value="No">
                {t("no")}
            </MenuItem>
        </TextField>
    );
}
