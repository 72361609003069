import * as React from "react";
import {createContext, PropsWithChildren, useContext, useEffect, useMemo, useState} from "react";
import {ITenant} from "../../domain/types";
import {useAuthContext} from "../auth/AuthContext";
import {CurrentUserProvider} from "../../domain/CurrentUserProvider";
import {RoleWrapper} from "../../domain/permissions";

export interface TenantContextData {
    tenantUuid: string;
    tenant?: ITenant;
    role?: RoleWrapper;
}

const emptyContext: TenantContextData = {
    tenantUuid: "00000000-0000-0000-0000-000000000000"
};

export const TenantContext = createContext<TenantContextData>(emptyContext);

interface TenantContextProviderProps extends PropsWithChildren<{
    tenantUuid: string;
}>{ }

export function TenantContextProvider(props: TenantContextProviderProps) {
    const {children} = props;
    const {api} = useAuthContext();
    const [tenantUuid, setTenantUuid] = useState<string>(props.tenantUuid);
    const [tenant, setTenant] = useState<ITenant>();
    const [role, setRole] = useState<RoleWrapper>();

    useEffect(() => {
        if (api.auth) {
            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider.tenant(tenantUuid).then(setTenant);
            currentUserProvider.role(tenantUuid)
                .then((role) => {
                    setRole(new RoleWrapper(role));
                });
        }
    }, [api, tenantUuid]);

    const currentContext = useMemo<TenantContextData>(() => ({
        tenantUuid, tenant, role
    }), [tenantUuid, tenant, role]);

    return (
        <TenantContext.Provider value={currentContext}>
            {children}
        </TenantContext.Provider>
    );
}

export function useTenantContext(): TenantContextData {
    return useContext<TenantContextData>(TenantContext);
}