import {Api} from "../libs/Api";
import {
    AssetModification,
    AssetRiskModification,
    IAsset,
    IAssetRisk,
    IMeasure,
    ITask,
    MeasureModification,
    Page,
    PageChange
} from "./types";
import {QuestionnaireWrapper} from "./questionnaires";

export class AssetsProvider {

    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async list(tenantUuid: string, pageChange: PageChange): Promise<Page<IAsset>> {
        const url = this.api.appendPagingParams(`/api/v1/tenants/${tenantUuid}/assets`, pageChange);
        return this.api.get<Page<IAsset>>(url);
    }

    async create(tenantUuid: string, name: string, toolUuid: string, questionnaire: QuestionnaireWrapper): Promise<IAsset> {
        const url = `/api/v1/tenants/${tenantUuid}/assets`;
        const body = {
            name,
            basedOn: toolUuid,
            questionnaireUuid: questionnaire.questionnaire.slug,
            answers: questionnaire.answers
        };
        return this.api.post<IAsset>(url, body);
    }

    async computeRisks(tenantUuid: string, questionnaire: QuestionnaireWrapper): Promise<IAssetRisk[]> {
        const url = `/api/v1/tenants/${tenantUuid}/assets/compute-risks`;
        const body = {
            answers: questionnaire.answers
        };
        return this.api.post<IAssetRisk[]>(url, body);
    }

    async get(tenantUuid: string, assetUuid: string): Promise<IAsset> {
        const url = `/api/v1/tenants/${tenantUuid}/assets/${assetUuid}`;
        return this.api.get(url);
    }

    async modify(tenantUuid: string, assetUuid: string, asset: AssetModification): Promise<IAsset> {
        const url = `/api/v1/tenants/${tenantUuid}/assets/${assetUuid}`;
        return this.api.put<IAsset>(url, asset);
    }

    async delete(tenantUuid: string, assetUuid: string): Promise<void> {
        const url = `/api/v1/tenants/${tenantUuid}/assets/${assetUuid}`;
        return this.api.delete(url);
    }

    async risks(tenantUuid: string, assetUuid: string): Promise<IAssetRisk[]> {
        const url = `/api/v1/tenants/${tenantUuid}/assets/${assetUuid}/risks`;
        return this.api.get<IAssetRisk[]>(url);
    }

    async risk(tenantUuid: string, assetUuid: string, riskUuid: string): Promise<IAssetRisk> {
        const url = `/api/v1/tenants/${tenantUuid}/assets/${assetUuid}/risks/${riskUuid}`;
        return this.api.get<IAssetRisk>(url);
    }

    async addRisk(tenantUuid: string, uuid: string, assetRisk: AssetRiskModification) {
        const url = `/api/v1/tenants/${tenantUuid}/assets/${uuid}/risks`;
        return this.api.post<IAssetRisk>(url, assetRisk);
    }

    async modifyRisk(tenantUuid: string, assetUuid: string, riskUuid: string, assetRisk: AssetRiskModification) {
        const url = `/api/v1/tenants/${tenantUuid}/assets/${assetUuid}/risks/${riskUuid}`;
        return this.api.put<IAssetRisk>(url, assetRisk);
    }

    async deleteRisk(tenantUuid: string, assetUuid: string, riskUuid: string) {
        const url = `/api/v1/tenants/${tenantUuid}/assets/${assetUuid}/risks/${riskUuid}`;
        return this.api.delete(url);
    }

    async measures(tenantUuid: string, assetUuid: string): Promise<IMeasure[]> {
        const url = `/api/v1/tenants/${tenantUuid}/assets/${assetUuid}/measures`;
        return this.api.get<IMeasure[]>(url);
    }

    async measure(tenantUuid: string, assetUuid: string, measureUuid: string): Promise<IMeasure> {
        const url = `/api/v1/tenants/${tenantUuid}/assets/${assetUuid}/measures/${measureUuid}`;
        return this.api.get<IMeasure>(url);
    }

    async addMeasure(tenantUuid: string, uuid: string, assetRiskUuid: string, measure: MeasureModification) {
        const url = `/api/v1/tenants/${tenantUuid}/assets/${uuid}/risks/${assetRiskUuid}/measures`;
        return this.api.post<IMeasure>(url, measure);
    }

    async modifyMeasure(tenantUuid: string, assetUuid: string, measureUuid: string, measure: MeasureModification) {
        const url = `/api/v1/tenants/${tenantUuid}/assets/${assetUuid}/measures/${measureUuid}`;
        return this.api.put<IMeasure>(url, measure);
    }

    async deleteMeasure(tenantUuid: string, assetUuid: string, measureUuid: string) {
        const url = `/api/v1/tenants/${tenantUuid}/assets/${assetUuid}/measures/${measureUuid}`;
        return this.api.delete(url);
    }

    async tasks(tenantUuid: string, assetUuid: string): Promise<ITask[]> {
        const url = `/api/v1/tenants/${tenantUuid}/assets/${assetUuid}/tasks`;
        return this.api.get<ITask[]>(url);
    }

}