import {Grid, Typography} from "@mui/material";
import * as React from "react";
import {KiToolSearchInput} from "../../../base/inputs/KiToolSearchInput";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {IKiTool} from "../../../domain/types";
import {gs} from "../../../theme";

interface StepToolSelectionProps {
    kiTool?: IKiTool;
    onKiToolChange: (kiTool: IKiTool) => void;
}

export function StepToolSelection(props: StepToolSelectionProps) {
    const {kiTool, onKiToolChange} = props;
    const {t} = useLanguageContext();
    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <KiToolSearchInput label={t("tools.kiTool.select")} value={kiTool} onChange={onKiToolChange} />
            </Grid>
            {kiTool && (
                <>
                    <Grid item xs={12}>
                        <Typography variant="h6">{kiTool.name}</Typography>
                    </Grid>
                    {kiTool.descriptions && kiTool.descriptions.length > 0 && (
                        <Grid item xs={12}>
                            <Typography variant="body1">{kiTool.descriptions[0].text}</Typography>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
}
