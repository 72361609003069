import * as React from "react";
import {IAssetRisk, IKiTool} from "../../../domain/types";
import {QuestionnaireWrapper} from "../../../domain/questionnaires";
import {Grid, List, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import {gs} from "../../../theme";
import {LabeledItem} from "../../../base/data/LabeledItem";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {TypeSpecimen} from "@mui/icons-material";
import {LabeledData} from "../../../base/data/LabeledData";
import {TotalRiskDisplay} from "../../../base/displays/TotalRiskDisplay";

interface StepSummaryProps {
    kiTool?: IKiTool;
    name?: string;
    questionnaire?: QuestionnaireWrapper;
    risks?: IAssetRisk[];
}

export function StepSummary(props: StepSummaryProps) {
    const {kiTool, name, questionnaire, risks} = props;
    const {t} = useLanguageContext();
    return (
        <Grid container spacing={gs}>
            {name && <LabeledItem label={t("name")}>{name}</LabeledItem>}
            {kiTool && name && kiTool.name != name && (
                <LabeledItem label={t("tools.kiTool.singular")}>{kiTool.name}</LabeledItem>
            )}
            {risks &&
                <Grid item xs={12}>
                    <LabeledData label={t("risks.plural")}>
                        <List>
                            {risks.map((r) => (
                                <ListItem key={r.category}>
                                    <ListItemAvatar>
                                        <TotalRiskDisplay totalRisk={r.risk.probability * r.risk.severity} />
                                    </ListItemAvatar>
                                    <ListItemText primary={r.name} secondary={r.category} />
                                </ListItem>
                            ))}
                        </List>
                    </LabeledData>
                </Grid>
            }
        </Grid>
    );
}
