import {Answer, AnswerScoreModifier, AnswerType, Question, Questionnaire} from "./types";

export class QuestionnaireWrapper {

    readonly questionnaire: Questionnaire;
    readonly questions: Question[];

    readonly answers: Answer[];

    constructor(questionnaire: Questionnaire, questions: Question[], answers: Answer[] = []) {
        this.questionnaire = questionnaire;

        const questionTree: Question[] = [];

        const questionsCopy = [...questions];
        while(questionsCopy.length > 0) {
            const question = questionsCopy.shift();
            if (!question) {
                throw new Error(`Could not build question tree`);
            }
            if (question.parent) {
                // Look the parent up in the tree, if it is not there, add it to the back of the list
                const parent = QuestionnaireWrapper.findInTree(questionTree, question.parent.uuid);
                if (parent) {
                    if (!parent.children) {
                        parent.children = [];
                    }
                    parent.children.push(question);
                } else {
                    questionsCopy.push(question);
                }
            } else {
                questionTree.push(question);
            }
        }

        this.questions = questionTree;
        this.answers = answers;
    }

    private static findInTree(tree: Question[], questionUuid: string): Question | undefined {
        for (let question of tree) {
            if (question.uuid === questionUuid) {
                return question;
            }
            if (question.children) {
                const found = QuestionnaireWrapper.findInTree(question.children, questionUuid);
                if (found) {
                    return found;
                }
            }
        }
        return undefined;
    }

    private static buildList(list: Question[], questions: Question[], answers: Answer[]) {
        for (let question of questions) {
            list.push(question);
            const wrapper = new QuestionWrapper(question);
            const answer = answers.find((a) => a.questionUuid === question.uuid);
            if (question.children && wrapper.showSubQuestions(answer)) {
                QuestionnaireWrapper.buildList(list, question.children, answers);
            }
        }
    }

    get questionsList(): Question[] {
        const list: Question[] = [];
        QuestionnaireWrapper.buildList(list, this.questions, this.answers);
        return list;
    }

    get numberOfQuestions(): number {
        return this.questionsList.length;
    }

    question(index: number): Question {
        const list = this.questionsList;
        if (index < 0 || index >= list.length) {
            throw new Error(`Question index out of bounds: ${index}`);
        }
        return list[index];
    }

    findAnswer(questionUuid: string): Answer | undefined {
        return this.answers.find((a) => a.questionUuid === questionUuid);
    }

    answer(questionUuid: string, answer: Answer): QuestionnaireWrapper {
        const existingAnswers = this.answers.filter((a) => a.questionUuid !== questionUuid);
        return new QuestionnaireWrapper(this.questionnaire, this.questions, [...existingAnswers, answer]);
    }

}

export class AnswerTypeWrapper {
    readonly answerType: AnswerType;

    constructor(answerType: AnswerType) {
        this.answerType = answerType;
    }

    evaluate(modifier: AnswerScoreModifier, answer: string, reference: string): boolean {
        switch (modifier) {
            case AnswerScoreModifier.Equals:
                return answer == reference;
            case AnswerScoreModifier.NotEquals:
                return answer != reference;
            case AnswerScoreModifier.TextMatches:
                return Boolean(answer) && Boolean(reference) && answer.toLowerCase() == reference.toLowerCase();
            case AnswerScoreModifier.TextContains:
                return (
                    Boolean(answer) && Boolean(reference) && answer.toLowerCase().indexOf(reference.toLowerCase()) != -1
                );
            case AnswerScoreModifier.Greater:
            case AnswerScoreModifier.GreaterOrEquals:
            case AnswerScoreModifier.Less:
            case AnswerScoreModifier.LessOrEquals:
            case AnswerScoreModifier.Multiply:
                return (
                    Boolean(answer) &&
                    Boolean(reference) &&
                    this.evaluateNumeric(modifier, this.parseNumber(answer), this.parseNumber(reference))
                );
            default:
                throw new Error(`AnswerTypeWrapper::evaluate() not implemented for modifier ${modifier}`);
        }
    }

    private evaluateNumeric(modifier: AnswerScoreModifier, answer: number, reference: number): boolean {
        switch (modifier) {
            case AnswerScoreModifier.Greater:
                return answer > reference;
            case AnswerScoreModifier.GreaterOrEquals:
                return answer >= reference;
            case AnswerScoreModifier.Less:
                return answer < reference;
            case AnswerScoreModifier.LessOrEquals:
                return answer <= reference;
            case AnswerScoreModifier.Multiply:
                return answer * reference > 0;
            default:
                throw new Error(
                    `AnswerTypeWrapper::evaluateNumeric() not implemented for answerType ${this.answerType} and modifier ${modifier}. AnswerType cannot contain numbers`
                );
        }
    }

    parseNumber(value: string): number {
        switch (this.answerType) {
            case AnswerType.Integer:
                if (value) {
                    return parseInt(value);
                }
                return 0;
            case AnswerType.Float:
                if (value) {
                    return parseFloat(value);
                }
                return 0;
            default:
                throw new Error(`AnswerType is ${this.answerType} cannot parse to number`);
        }
    }

}

export class QuestionWrapper {

    readonly question: Question;

    constructor(question: Question) {
        this.question = question;
    }

    showSubQuestions(answer?: Answer): boolean {
        if (this.question.children && this.question.children) {
            const s = this.question.showSubQuestions;
            if (s.always) {
                return true;
            } else if (answer) {
                const answerTypeWrapper = new AnswerTypeWrapper(this.question.type);
                return answerTypeWrapper.evaluate(s.modifier, answer.answer, s.answer || "");
            }
        }
        return false;
    }

}
