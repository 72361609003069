import * as React from "react";
import {PropsWithChildren, useEffect} from "react";
import { AHeader } from "./AHeader";
import {useLayoutContext} from "../../context/layout/LayoutContext";

interface APageHeaderProps extends PropsWithChildren<{
    title: string;
}> {}

export function APageHeader(props: APageHeaderProps) {
    const {title, children} = props;

    const {setTitle} = useLayoutContext();

    useEffect(() => {
        setTitle(title);
    }, [title, setTitle]);

    return (
        <AHeader title={title} variant="h1">{children}</AHeader>
    );
}
