import * as React from "react";
import {createContext, PropsWithChildren, useCallback, useContext, useMemo, useState} from "react";

export interface LayoutContextData {
    title: string;
    setTitle: (title: string) => void;
    drawerNavOpen: boolean;
    setDrawerNavOpen: (open: boolean) => void;
}

const emptyContext: LayoutContextData = {
    title: "Artiligence",
    setTitle: (title: string) => {},
    drawerNavOpen: false,
    setDrawerNavOpen: (open: boolean) => {}
};

export const LayoutContext = createContext<LayoutContextData>(emptyContext);

interface LayoutContextProps extends PropsWithChildren<{ }> {}

export function LayoutContextProvider(props: LayoutContextProps) {
    const {children} = props;

    const [title, setTitle] = useState<string>(emptyContext.title);
    const [drawerNavOpen, setDrawerNavOpen] = useState<boolean>(emptyContext.drawerNavOpen);

    const handleSetTitle = useCallback((newTitle: string) => {
        setTitle(newTitle);
        document.title = `${newTitle} - Artiligence`;
    }, []);

    const currentContext = useMemo<LayoutContextData>(() => ({
        title, setTitle: handleSetTitle,
        drawerNavOpen, setDrawerNavOpen
    }), [title, handleSetTitle, drawerNavOpen, setDrawerNavOpen]);

    return (
        <LayoutContext.Provider value={currentContext}>
            {children}
        </LayoutContext.Provider>
    );
}

export function useLayoutContext(): LayoutContextData {
    return useContext<LayoutContextData>(LayoutContext);
}
