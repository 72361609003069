import {Button, Grid} from "@mui/material";
import * as React from "react";
import {IAsset, IAssetRisk, IMeasure} from "../../../domain/types";
import {gs} from "../../../theme";
import {AHeader} from "../../../base/layout/AHeader";
import {useEffect, useState} from "react";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import {ATable} from "../../../base/table/ATable";
import {GridColDef} from "@mui/x-data-grid";
import {useFetcher, useNavigate} from "react-router-dom";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {ProbabilityDisplay} from "../../../base/displays/ProbabilityDisplay";
import {SeverityDisplay} from "../../../base/displays/SeverityDisplay";
import {TotalRiskDisplay} from "../../../base/displays/TotalRiskDisplay";
import {useLanguageContext} from "../../../context/language/LanguageContext";

interface AssetRisksBlockProps {
    asset: IAsset;
}

interface AssetRisksAligned {
    assetRisk: IAssetRisk;
    measure?: IMeasure;
}

export function AssetRisksBlock(props: AssetRisksBlockProps) {
    const {asset} = props;
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const {t} = useLanguageContext();
    const navigate = useNavigate();

    const [risks, setRisks] = useState<IAssetRisk[]>();
    const [alignedRisks, setAlignedRisks] = useState<AssetRisksAligned[]>();
    const [selected, setSelected] = useState<string[]>([]);

    const columns: GridColDef[] = [
        {field: "assetRisk.name", headerName: t("assets.headerNames.name"), flex: 1, renderCell: (r) => r.row.assetRisk.name},
        {field: "assetRisk.category", headerName: t("assets.headerNames.category"), flex: 1, renderCell: (r) => r.row.assetRisk.category},
        {
            field: "assetRisk.probability",
            headerName: t("assets.headerNames.probability"),
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (r) => <ProbabilityDisplay probability={r.row.assetRisk.risk.probability} />
        },
        {
            field: "assetRisk.severity",
            headerName: t("assets.headerNames.severity"),
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (r) => <SeverityDisplay severity={r.row.assetRisk.risk.severity} />
        },
        {
            field: "assetRisk.grossRisk",
            headerName: t("assets.headerNames.grossRisk"),
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (r) => <TotalRiskDisplay totalRisk={r.row.assetRisk.risk.probability * r.row.assetRisk.risk.severity} />
        },
        {
            field: "measures.netRisk",
            headerName: t("assets.headerNames.netRisk"),
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (r) =>
                r.row.measure ?
                    <TotalRiskDisplay totalRisk={r.row.measure.risk.probability * r.row.measure.risk.severity} /> :
                    null

        }
    ];

    useEffect(() => {
        if (api.auth) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.risks(tenantUuid, asset.uuid).then(setRisks);
        }
    }, [api, tenantUuid, asset]);

    useEffect(() => {
        if (risks) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.measures(tenantUuid, asset.uuid).then((measures) => {
                const aligned: AssetRisksAligned[] = risks.map((r) => {
                    const measure = measures
                        .filter((m) => m.assetRisk && m.assetRisk.uuid === r.uuid)
                        .sort((a, b) => a.priority - b.priority)
                        .pop();
                    return {assetRisk: r, measure};
                });
                setAlignedRisks(aligned);
            });
        }
    }, [api, tenantUuid, asset, risks]);

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <AHeader title={t("risks.plural")} variant="h2">
                    <Button onClick={() => navigate(`/tenants/${tenantUuid}/assets/${asset.uuid}/risks/add`)}>
                        {t("risks.addRisk")}
                    </Button>
                </AHeader>
            </Grid>
            {alignedRisks && (
                <Grid item xs={12}>
                    <ATable
                        rows={alignedRisks}
                        columns={columns}
                        onClick={(row) => navigate(`/tenants/${tenantUuid}/assets/${asset.uuid}/risks/${row.assetRisk.uuid}`)}
                        onSelectionChanged={setSelected}
                        getRowId={(r) => r.assetRisk.uuid}
                    />
                </Grid>
            )}
        </Grid>
    );
}
