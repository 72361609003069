import * as React from "react";
import {ConfirmButton} from "./ConfirmButton";
import {PropsWithChildren} from "react";

interface ConfirmDeleteButtonProps extends PropsWithChildren<{
    onConfirm: () => void;
    title: string;
    message: string;
    deleteButtonText?: string;
}> { }

export function ConfirmDeleteButton(props: ConfirmDeleteButtonProps) {
    const {onConfirm, title, message, deleteButtonText, children} = props;

    return (
        <ConfirmButton onConfirm={onConfirm} title={title} message={message} confirmButtonText={deleteButtonText} color="error">
            {children}
        </ConfirmButton>
    );
}
