import * as React from "react";
import {IAsset, IAssetRisk} from "../../../domain/types";
import {useEffect, useMemo, useState} from "react";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {AiPosture, randomAiPosture} from "../../../domain/aiPosture";
import {ArtiligenceSettings} from "../../../domain/artiligenceSettings";
import {AiPostureRadarChart} from "../../../charts/AiPostureRadarChart";
import {Paper} from "@mui/material";

interface AssetRisksChartProps {
    asset: IAsset;
}

export function AssetRisksChart(props: AssetRisksChartProps) {
    const {asset} = props;
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const [settings, setSettings] = useState<ArtiligenceSettings>(new ArtiligenceSettings());

    const [safeRange, setSafeRange] = useState(settings.safeRange);
    const [dangerRange, setDangerRange] = useState(settings.dangerRange);

    const [risks, setRisks] = useState<IAssetRisk[]>();

    const posture = useMemo<AiPosture | undefined>(() => {
        if (risks) {
            return {
                id: asset.uuid,
                label: asset.name,
                color: [40, 75, 114],
                values: risks.map((risk) => ({
                    dimension: risk.category,
                    value: risk.risk.probability * risk.risk.severity
                }))
            };
        }
        return undefined;
    }, [asset, risks]);

    useEffect(() => {
        if (api.auth) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.risks(tenantUuid, asset.uuid).then(setRisks);
        }
    }, [api, tenantUuid, asset]);

    if (!posture) {
        return null;
    }

    return (
        <Paper>
            <AiPostureRadarChart postures={[posture]} safeRange={safeRange} dangerRange={dangerRange} />
        </Paper>
    );
}
