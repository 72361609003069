import * as React from "react";
import {AppRoutes} from "./AppRoutes";
import {BrowserRouter} from "react-router-dom";
import {LayoutContextProvider} from "./context/layout/LayoutContext";
import {AuthContextProvider} from "./context/auth/AuthContext";
import {TopNav} from "./nav/TopNav";
import {DrawerNav} from "./nav/DrawerNav";
import Box from "@mui/material/Box";
import {BottomNav} from "./nav/BottomNav";
import {LanguageContextProvider} from "./context/language/LanguageContext";
export default function App() {
    return (
        <BrowserRouter>
            <LanguageContextProvider>
                <LayoutContextProvider>
                    <AuthContextProvider notAuthenticatedUrl="/auth/login">
                        <TopNav />
                        <DrawerNav />
                        <Box p={4} />
                        <AppRoutes />
                        <Box p={5} />
                        <BottomNav />
                    </AuthContextProvider>
                </LayoutContextProvider>
            </LanguageContextProvider>
        </BrowserRouter>
    );
}
