import * as React from "react";
import {IMeasure} from "../../domain/types";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {List, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import {MultiLingualTextDisplay} from "../../base/displays/MultiLingualTextDisplay";
import {PriorityDisplay} from "../../base/displays/PriorityDisplay";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {useTenantContext} from "../../context/tenant/TenantContext";

interface MeasuresListProps {
    measures: IMeasure[];
}

export function MeasuresList(props: MeasuresListProps) {
    const {measures} = props;
    const {tenantUuid} = useTenantContext();
    const {t} = useLanguageContext();
    const navigate = useNavigate();

    const openAssetForMeasure = useCallback((m: IMeasure) => {
        navigate(`/tenants/${tenantUuid}/assets/${m.asset.uuid}/measures/${m.uuid}`);
    }, []);

    return (
        <List>
            {measures.map((m) =>
                <ListItem key={m.uuid} button onClick={() => openAssetForMeasure(m)}>
                    <ListItemAvatar>
                        <PriorityDisplay priority={m.priority} />
                    </ListItemAvatar>
                    <ListItemText primary={<MultiLingualTextDisplay texts={m.titles} />}
                                  secondary={m.asset.name} />
                </ListItem>
            )}
            {measures.length == 0 &&
                <ListItem>
                    <ListItemText primary={t("dashboard.noMeasures")} />
                </ListItem>
            }
        </List>
    );
}
