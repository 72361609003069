import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slider} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useCallback, useMemo} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface TuneDialogProps {
    open: boolean;
    safeRange: number;
    dangerRange: number;
    onSafeRangeChange: (value: number) => void;
    onDangerRangeChange: (value: number) => void;
    onClose: () => void;
}

export function TuneDialog(props: TuneDialogProps) {
    const {open, safeRange, dangerRange, onSafeRangeChange, onDangerRangeChange, onClose} = props;

    const {t} = useLanguageContext();

    const values = useMemo<number[]>(() => [safeRange, dangerRange], [safeRange, dangerRange]);

    const handleChange = useCallback(
        (event: Event, value: number | number[]) => {
            if (Array.isArray(value)) {
                const safeRangeMoved = value[0] !== safeRange;
                const dangerRangeMoved = value[1] !== dangerRange;
                if (safeRangeMoved && value[0] >= value[1]) {
                    value[1] = value[0] + 0.25;
                }
                if (dangerRangeMoved && value[1] <= value[0]) {
                    value[0] = value[1] - 0.25;
                }
                onSafeRangeChange(value[0]);
                onDangerRangeChange(value[1]);
            }
        },
        [safeRange, onSafeRangeChange, dangerRange, onDangerRangeChange]
    );

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t("radar.chartSettings")}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="body2">{t("radar.setRanges")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Slider
                            value={values}
                            onChange={handleChange}
                            min={0}
                            max={5}
                            step={0.25}
                            disableSwap
                            valueLabelDisplay="auto"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t("close")}</Button>
            </DialogActions>
        </Dialog>
    );
}
