import * as React from "react";
import {useEffect, useState} from "react";
import {useAuthContext} from "../../context/auth/AuthContext";
import {UsersProvider} from "../../domain/UsersProvider";
import {UserInfo} from "../../domain/types";

interface UserDisplayProps {
    username: string;
}

export function UserDisplay(props: UserDisplayProps) {
    const {username} = props;
    const {api} = useAuthContext();

    const [userInfo, setUserInfo] = useState<UserInfo>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (api.auth) {
            const usersProvider = new UsersProvider(api);
            usersProvider
                .getUserInfo(username)
                .then(setUserInfo)
                .catch((error) => console.log(error.message))
                .finally(() => setLoading(false));
        }
    }, [username, api]);

    if (loading) {
        return <span>...</span>;
    }

    if (userInfo) {
        if (userInfo.name) {
            return (
                <span>
                    {userInfo.givenName} {userInfo.name}
                </span>
            );
        } else {
            return <span>{userInfo.email}</span>;
        }
    }

    return <span>{username}</span>;
}
