import * as React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {defaultPage, IAsset, Page, PageChange, Role} from "../../domain/types";
import {RolesProvider} from "../../domain/RolesProvider";
import {GridColDef} from "@mui/x-data-grid";
import {Container, Grid} from "@mui/material";
import {gs} from "../../theme";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {APagingTable} from "../../base/table/APagingTable";

interface RolesListProps {
}

export function RolesList(props: RolesListProps) {
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();

    const [pageChange, setPageChange] = useState<PageChange>(defaultPage);
    const [roles, setRoles] = useState<Page<Role>>();
    const [selected, setSelected] = useState<string[]>([]);

    useEffect(() => {
        const rolesProvider = new RolesProvider(api);
        if (api.auth) {
            rolesProvider.list(tenantUuid, pageChange).then(setRoles);
        }
    }, [api, tenantUuid, pageChange]);

    const {t} = useLanguageContext();
    const columns: GridColDef[] = [
        {field: "name", headerName: t("roles.name"), flex: 1},
        {field: "description", headerName: t("roles.description"), flex: 1},
        {field: "permissions", headerName: t("roles.permissions"), flex: 1,
            valueGetter: (params) => params.row.permissions.permissions.length}
    ];

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("roles.plural")}
                        crumbs={[{label: t("roles.plural"), href: `/tenants/${tenantUuid}/roles`}]} />
                </Grid>
                {roles && (
                    <Grid item xs={12}>
                        <APagingTable
                            page={roles}
                            columns={columns}
                            onPageChanged={setPageChange}
                            onClick={(role: Role) => navigate(`/tenants/${tenantUuid}/roles/${role.uuid}`)}
                            onSelectionChanged={setSelected}
                        />
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
