import * as React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {QuestionnaireWrapper} from "../../domain/questionnaires";
import {SimpleTreeView, TreeItem} from "@mui/x-tree-view";
import {MultilingualTextWrapper, Question} from "../../domain/types";

interface QuestionsTreeProps {
    questionnaire: QuestionnaireWrapper;
    onSelect: (question: Question) => void;
}

export function QuestionsTree(props: QuestionsTreeProps) {
    const {questionnaire, onSelect} = props;
    const {language} = useLanguageContext();

    return (
        <SimpleTreeView>
            {questionnaire.questions.map((question: Question) => (
                <QuestionTreeItem key={question.uuid} question={question} language={language} onSelect={onSelect} />
            ))}
        </SimpleTreeView>
    );
}

interface QuestionTreeItemProps {
    question: Question;
    language: string;
    onSelect: (question: Question) => void;
}

function QuestionTreeItem(props: QuestionTreeItemProps) {
    const {question, language, onSelect} = props;

    return (
        <TreeItem itemId={question.uuid} label={new MultilingualTextWrapper(question.titles).resolved(language)} onClick={() => onSelect(question)}>
            {question.children ? question.children.map((child: Question) => (
                <QuestionTreeItem key={child.uuid} question={child} language={language} onSelect={onSelect} />
            )) : null}
        </TreeItem>
    );
}