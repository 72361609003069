import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {AssetRiskModification, IAsset, RiskCategory} from "../../../domain/types";
import {gs} from "../../../theme";
import {Button, Grid} from "@mui/material";
import {AHeaderWithBreadcrumbs} from "../../../base/layout/AHeaderWithBreadcrumbs";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import Container from "@mui/material/Container";
import {BoxedPaper} from "../../../base/layout/BoxedPaper";
import {AssetRiskForm} from "./AssetRiskForm";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";

interface AssetAddRiskProps {}

export function AssetAddRisk(props: AssetAddRiskProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const {t} = useLanguageContext();
    const navigate = useNavigate();

    const [asset, setAsset] = useState<IAsset>();
    const [assetRisk, setAssetRisk] = useState<AssetRiskModification>({
        name: "",
        category: RiskCategory.Copyright,
        descriptions: [],
        risk: {
            probability: 1,
            severity: 1
        }
    });
    const [submitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (routeParams.assetUuid && api.auth) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.get(tenantUuid, routeParams.assetUuid).then(setAsset);
        }
    }, [api, tenantUuid, routeParams]);

    const canCreate = useMemo<boolean>(
        () => Boolean(assetRisk.name && assetRisk.category && assetRisk.descriptions.length > 0),
        [assetRisk]
    );

    const handleAddRisk = useCallback(() => {
        if (asset && canCreate && api.auth) {
            setSubmitting(true);
            const assetsProvider = new AssetsProvider(api);
            assetsProvider
                .addRisk(tenantUuid, asset.uuid, assetRisk)
                .then(() => {
                    navigate(`/tenants/${tenantUuid}/assets/${asset.uuid}`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    }, [canCreate, assetRisk, api, tenantUuid, asset, navigate]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("risks.addRisk")}
                        crumbs={[
                            {label: t("assets.plural"), href: `/tenants/${tenantUuid}/assets`},
                            {
                                label: asset ? asset.name : "...",
                                href: asset ? `/tenants/${tenantUuid}/assets/${asset.uuid}` : "/assets"
                            },
                            {
                                label: t("risks.addRisk"),
                                href: asset ? `/tenants/${tenantUuid}/assets/${asset.uuid}/risks/add` : "/assets"
                            }
                        ]}
                    ></AHeaderWithBreadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <BoxedPaper loading={submitting}>
                        <Grid container spacing={gs}>
                            <Grid item xs={12}>
                                <AssetRiskForm assetRisk={assetRisk} onChange={setAssetRisk} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    onClick={handleAddRisk}
                                    color="primary"
                                    variant="contained"
                                    disabled={!canCreate || submitting}
                                >
                                    {t("risks.addRisk")}
                                </Button>
                            </Grid>
                        </Grid>
                    </BoxedPaper>
                </Grid>
            </Grid>
        </Container>
    );
}
