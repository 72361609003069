import {MenuItem, TextField} from "@mui/material";
import * as React from "react";
import {useCallback} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface SeverityInputProps {
    label: string;
    value: number;
    onChange: (value: number) => void;
    disabled?: boolean;
}

export function SeverityInput(props: SeverityInputProps) {
    const {label, value, onChange, disabled} = props;
    const {t} = useLanguageContext();

    const handleChange = useCallback(
        (value: number) => {
            onChange(value);
        },
        [onChange]
    );

    return (
        <TextField
            label={label}
            variant="outlined"
            fullWidth
            select
            value={value}
            disabled={disabled}
            onChange={(event) => handleChange(parseInt(event.target.value))}
        >
            <MenuItem key={1} value={1}>
                {t("severity.severities.VeryLow")}
            </MenuItem>
            <MenuItem key={2} value={2}>
                {t("severity.severities.Low")}
            </MenuItem>
            <MenuItem key={3} value={3}>
                {t("severity.severities.Medium")}
            </MenuItem>
            <MenuItem key={4} value={4}>
                {t("severity.severities.High")}
            </MenuItem>
            <MenuItem key={5} value={5}>
                {t("severity.severities.VeryHigh")}
            </MenuItem>
        </TextField>
    );
}
