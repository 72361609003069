import {Api} from "../libs/Api";
import {IAssetRisk, IMeasure} from "./types";

export class RisksProvider {

    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async topTen(tenantUuid: string): Promise<IAssetRisk[]> {
        const url = `/api/v1/tenants/${tenantUuid}/risks/top-ten`;
        return this.api.get<IAssetRisk[]>(url);
    }

}