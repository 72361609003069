import * as React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useNavigate, useParams} from "react-router-dom";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {gs} from "../../theme";
import {Container, Grid, Paper, Typography} from "@mui/material";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {useEffect, useMemo, useState} from "react";
import {MultilingualTextWrapper, Question, Questionnaire} from "../../domain/types";
import {QuestionnairesProvider} from "../../domain/QuestionnairesProvider";
import {MetaDataDisplay} from "../../base/data/MetaDataDisplay";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {QuestionnaireWrapper} from "../../domain/questionnaires";
import {SimpleTreeView} from "@mui/x-tree-view";
import {QuestionsTree} from "./QuestionsTree";
import Box from "@mui/material/Box";
import {LabeledData} from "../../base/data/LabeledData";
import {MultiLingualTextDisplay} from "../../base/displays/MultiLingualTextDisplay";

interface QuestionnaireViewProps {
}

export function QuestionnaireView(props: QuestionnaireViewProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const {t, language} = useLanguageContext();

    const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
    const [questions, setQuestions] = useState<Question[]>();
    const [selectedQuestion, setSelectedQuestion] = useState<Question>();

    useEffect(() => {
        if (routeParams.slug && api.auth) {
            const questionnairesProvider = new QuestionnairesProvider(api);
            questionnairesProvider.get(tenantUuid, routeParams.slug).then(setQuestionnaire);
            questionnairesProvider.questions(tenantUuid, routeParams.slug).then(setQuestions);
        }
    }, [api, tenantUuid, routeParams]);

    const wrapper = useMemo<QuestionnaireWrapper | undefined>(() => {
        if (questionnaire && questions) {
            return new QuestionnaireWrapper(questionnaire, questions);
        }
        return undefined;
    }, [questionnaire, questions]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={questionnaire ? new MultilingualTextWrapper(questionnaire.titles).resolved(language) : "..."}
                        crumbs={[
                            {label: t("questionnaires.plural"), href: `/tenants/${tenantUuid}/questionnaires`},
                            {label: questionnaire ? new MultilingualTextWrapper(questionnaire.titles).resolved(language) : "...", href: `/tenants/${tenantUuid}/questionnaires/${routeParams.slug}`}
                        ]} />
                </Grid>
                {wrapper &&
                    <>
                        <Grid item xs={12} sm={6} md={4}>
                            <Paper>
                                <QuestionsTree questionnaire={wrapper} onSelect={setSelectedQuestion} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={8}>
                            <BoxedPaper>
                                {!selectedQuestion &&
                                    <Box p={2}>
                                        <Typography variant="body2" align="center">{t("questionnaires.noQuestionSelected")}</Typography>
                                    </Box>
                                }
                                {selectedQuestion &&
                                    <Grid container spacing={gs}>
                                        <Grid item xs={12}>
                                            <LabeledData label={t("questionnaires.title")}>
                                                <MultiLingualTextDisplay texts={selectedQuestion.titles} />
                                            </LabeledData>
                                        </Grid>
                                        {selectedQuestion.descriptions.length > 0 &&
                                            <Grid item xs={12}>
                                                <LabeledData label={t("questionnaires.description")}>
                                                    <MultiLingualTextDisplay texts={selectedQuestion.descriptions} />
                                                </LabeledData>
                                            </Grid>
                                        }
                                    </Grid>
                                }
                            </BoxedPaper>
                        </Grid>
                    </>
                }
                {questionnaire &&
                    <Grid item xs={12}>
                        <BoxedPaper>
                            <MetaDataDisplay metaData={questionnaire?.metaData} />
                        </BoxedPaper>
                    </Grid>
                }
            </Grid>
        </Container>
    );
}
