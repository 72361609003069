import {Chip} from "@mui/material";
import {green, orange, red} from "@mui/material/colors";
import * as React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {MeasureState} from "../../domain/types";

interface MeasureStateDisplayProps {
    state: MeasureState;
}

export function MeasureStateDisplay(props: MeasureStateDisplayProps) {
    const {state} = props;
    const {t} = useLanguageContext();

    let backgroundColor = undefined;
    let color = undefined;
    let label = t("measures.labels.Unknown");
    switch (state) {
        case MeasureState.Identified:
            backgroundColor = red[700];
            color = "white";
            label = t("measures.labels.Identified");
            break;
        case MeasureState.InProgress:
            backgroundColor = orange[300];
            label = t("measures.labels.InProgress");
            break;
        case MeasureState.Completed:
            backgroundColor = green[700];
            color = "white";
            label = t("measures.labels.Completed");
            break;
    }

    return <Chip label={label} sx={{backgroundColor, color}} />;
}
