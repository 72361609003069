import {Grid} from "@mui/material";
import * as React from "react";
import {IAsset} from "../../../domain/types";
import {gs} from "../../../theme";
import {LabeledItem} from "../../../base/data/LabeledItem";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {ALink} from "../../../base/layout/ALink";
import {useLanguageContext} from "../../../context/language/LanguageContext";

interface AssetDetailsProps {
    asset: IAsset;
}

export function AssetDetails(props: AssetDetailsProps) {
    const {asset} = props;
    const {tenantUuid} = useTenantContext();
    const {t} = useLanguageContext();

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <Grid container spacing={gs}>
                    <LabeledItem label={t("assets.headerNames.type")}>{asset.type}</LabeledItem>
                    <LabeledItem label={t("assets.headerNames.category")}>{asset.category}</LabeledItem>
                    <LabeledItem label={t("assets.headerNames.visibility")}>{asset.visibility}</LabeledItem>
                    <LabeledItem label={t("assets.headerNames.vendor")}>{asset.vendor}</LabeledItem>
                    <LabeledItem label={t("assets.headerNames.basedOn")}>
                        <ALink href={`/tenants/${tenantUuid}/tools/${asset.basedOn.uuid}`}>{asset.basedOn.name}</ALink>
                    </LabeledItem>
                </Grid>
            </Grid>
            <LabeledItem label={t("assets.headerNames.description")}>
                {asset.descriptions.map((d) => d.text).join(", ")}
            </LabeledItem>
        </Grid>
    );
}
