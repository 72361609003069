import * as React from "react";
import {Question} from "../../domain/types";
import {useCallback, useMemo} from "react";
import {MultiStringSelect} from "../../base/inputs/MultiStringSelect";

interface MultipleChoiceMultipleSelectInputProps {
    question: Question;
    value: string;
    onChange: (value: string) => void;
}

export function MultipleChoiceMultipleSelectInput(props: MultipleChoiceMultipleSelectInputProps) {
    const {question, value, onChange} = props;

    const lookupLabel = useCallback((slug: string) => {
        if (question.options) {
            const option = question.options.find((o) => o.slug == slug);
            if (option) {
                return option.label;
            }
        }
        return slug;
    }, [question]);

    const values = useMemo<string[]>(() =>
        value
            .split(",")
            .map((v) => v.trim())
            .filter((v) => v.length > 0)
            .sort((a, b) => a.localeCompare(b))
    , [value]);

    const options = useMemo<string[]>(() => {
        if (question.options) {
            return question.options.map((o) => o.slug);
        }
        return [];
    }, [question]);

    const handleChange = useCallback((values: string[]) => {
        onChange(values.join(","));
    }, [onChange]);

    return (
        <MultiStringSelect values={values} options={options} onChange={handleChange} lookupLabel={lookupLabel} />
    );
}
