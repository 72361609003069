import * as React from "react";
import {IAssetRisk} from "../../domain/types";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {List, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {SeverityDisplay} from "../../base/displays/SeverityDisplay";

interface RisksListProps {
    risks: IAssetRisk[];
}

export function RisksList(props: RisksListProps) {
    const {risks} = props;
    const {tenantUuid} = useTenantContext();
    const {t} = useLanguageContext();
    const navigate = useNavigate();

    const openAssetForRisk = useCallback((r: IAssetRisk) => {
        navigate(`/tenants/${tenantUuid}/assets/${r.asset.uuid}/risks/${r.uuid}`);
    }, []);

    return (
        <List>
            {risks.map((r) =>
                <ListItem key={r.uuid} button onClick={() => openAssetForRisk(r)}>
                    <ListItemAvatar>
                        <SeverityDisplay severity={r.risk.severity} trafficLight />
                    </ListItemAvatar>
                    <ListItemText primary={r.name}
                                  secondary={r.asset.name} />
                </ListItem>
            )}
            {risks.length == 0 &&
                <ListItem>
                    <ListItemText primary={t("dashboard.noRisks")} />
                </ListItem>
            }
        </List>
    );
}
