import * as React from "react";
import {IAssetRisk} from "../../domain/types";
import {MenuItem, TextField} from "@mui/material";
import {useCallback} from "react";

interface RiskSelectInputProps {
    label: string;
    options: IAssetRisk[];
    value?: IAssetRisk;
    onChange: (value: IAssetRisk) => void;
    disabled?: boolean;
}

export function RiskSelectInput(props: RiskSelectInputProps) {
    const {label, options, value, onChange, disabled} = props;

    const handleChange = useCallback(
        (value: string) => {
            const selected = options.find((o) => o.uuid === value);
            if (selected) {
                onChange(selected);
            }
        },
        [options, onChange]
    );

    return (
        <TextField
            label={label}
            variant="outlined"
            fullWidth
            select
            value={value}
            disabled={disabled}
            onChange={(event) => handleChange(event.target.value)}
        >
            {options.map((v) => (
                <MenuItem key={v.uuid} value={v.uuid}>
                    {v.name}
                </MenuItem>
            ))}
        </TextField>
    );
}
