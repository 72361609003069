import * as React from "react";
import {Role} from "../../domain/types";
import {Chip, Tooltip} from "@mui/material";

interface RoleDisplayProps {
    role: Role;
}

export function RoleDisplay(props: RoleDisplayProps) {
    const {role} = props;
    return (
        <Tooltip title={role.description}>
            <Chip label={role.name} />
        </Tooltip>
    );
}
