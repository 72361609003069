import {Chart as ChartJS, Filler, Legend, LineElement, PointElement, RadialLinearScale, Tooltip} from "chart.js";
import * as React from "react";
import {useEffect, useState} from "react";
import {Radar} from "react-chartjs-2";
import {useLanguageContext} from "../context/language/LanguageContext";
import {AiPosture, AiPosturesWrapper, fixedRange} from "../domain/aiPosture";

interface AiPostureRadarChartProps {
    postures: AiPosture[];
    safeRange: number;
    dangerRange: number;
}

export function AiPostureRadarChart(props: AiPostureRadarChartProps) {
    const {safeRange, dangerRange, postures} = props;

    ChartJS.register(PointElement, LineElement, RadialLinearScale, Tooltip, Legend, Filler);

    const [data, setData] = useState<AiPosturesWrapper>();
    const {t} = useLanguageContext();
    useEffect(() => {
        const safeRangePosture = fixedRange("safeRange", t("chart.safeRange"), [66, 148, 100], safeRange);
        const dangerRangePosture = fixedRange("dangerRange", t("chart.dangerRange"), [255, 0, 0], dangerRange);
        const data = new AiPosturesWrapper(postures, safeRangePosture, dangerRangePosture);
        setData(data);
    }, [postures, safeRange, dangerRange]);

    if (!data) return <div>{t("loading")}</div>;

    return <Radar data={data.toRadarChartData()} options={{scales: {r: {suggestedMin: 0, suggestedMax: 5}}}} />;
}
