import {Grid, List, ListItem, ListItemText, Paper} from "@mui/material";
import Container from "@mui/material/Container";
import * as React from "react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {CurrentUserProvider} from "../../domain/CurrentUserProvider";
import {ITenant} from "../../domain/types";
import {gs} from "../../theme";

interface TenantSelectProps {}

export function TenantSelect(props: TenantSelectProps) {
    const {api} = useAuthContext();
    const navigate = useNavigate();
    const {t} = useLanguageContext();

    const [tenants, setTenants] = useState<ITenant[]>();

    useEffect(() => {
        console.log("Tenant select")
        if (api && api.auth) {
            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider.tenants().then((tenants) => {
                setTenants(tenants);
                if (tenants.length == 1) {
                    navigate(`/tenants/${tenants[0].uuid}`);
                }
            });
        }
    }, [api]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("tenant.select")}
                        crumbs={[{label: t("logout.singular"), href: "/auth/logout"}]}
                    />
                </Grid>
                {tenants && (
                    <Grid item xs={12}>
                        <Paper>
                            <List>
                                {tenants.map((tenant) => (
                                    <ListItem
                                        key={tenant.uuid}
                                        button
                                        onClick={() => navigate(`/tenants/${tenant.uuid}`)}
                                    >
                                        <ListItemText primary={tenant.name} />
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
