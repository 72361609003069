import {Grid} from "@mui/material";
import * as React from "react";
import {TextInput} from "../../../base/inputs/TextInput";
import {AssetRiskModification, RiskCategory} from "../../../domain/types";
import {gs} from "../../../theme";
import {useCallback, useEffect, useState} from "react";
import {ProbabilityInput} from "../../../base/inputs/ProbabilityInput";
import {SeverityInput} from "../../../base/inputs/SeverityInput";
import {EnumSelectInput} from "../../../base/inputs/EnumSelectInput";
import {useLanguageContext} from "../../../context/language/LanguageContext";

interface AssetRiskFormProps {
    assetRisk: AssetRiskModification;
    onChange: (value: AssetRiskModification) => void;
}

export function AssetRiskForm(props: AssetRiskFormProps) {
    const {assetRisk, onChange} = props;

    const {t} = useLanguageContext();

    const [name, setName] = useState<string>(assetRisk.name);
    const [category, setCategory] = useState<RiskCategory>(assetRisk.category);
    const [description, setDescription] = useState<string>("");
    const [probability, setProbability] = useState<number>(1);
    const [severity, setSeverity] = useState<number>(1);

    useEffect(() => {
        setName(assetRisk.name);
        setCategory(assetRisk.category);
        if (assetRisk.descriptions.length > 0) {
            setDescription(assetRisk.descriptions[0].text);
        }
        setProbability(assetRisk.risk.probability);
        setSeverity(assetRisk.risk.severity);
    }, [assetRisk]);

    const handleNameChanged = useCallback(
        (name: string) => {
            setName(name);
            onChange({
                name,
                category,
                descriptions: [{locale: "en", text: description}],
                risk: {probability, severity}
            });
        },
        [category, description, probability, severity, onChange]
    );

    const handleCategoryChanged = useCallback(
        (category: RiskCategory) => {
            setCategory(category);
            onChange({
                name,
                category,
                descriptions: [{locale: "en", text: description}],
                risk: {probability, severity}
            });
        },
        [name, description, probability, severity, onChange]
    );

    const handleDescriptionChanged = useCallback(
        (description: string) => {
            setDescription(description);
            onChange({
                name,
                category,
                descriptions: [{locale: "en", text: description}],
                risk: {probability, severity}
            });
        },
        [name, category, probability, severity, onChange]
    );

    const handleProbabilityChanged = useCallback(
        (probability: number) => {
            setProbability(probability);
            onChange({
                name,
                category,
                descriptions: [{locale: "en", text: description}],
                risk: {probability, severity}
            });
        },
        [name, description, category, severity, onChange]
    );

    const handleSeverityChanged = useCallback(
        (severity: number) => {
            setSeverity(severity);
            onChange({
                name,
                category,
                descriptions: [{locale: "en", text: description}],
                risk: {probability, severity}
            });
        },
        [name, description, category, probability, onChange]
    );

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12} sm={12} md={6}>
                <TextInput label={t("assets.headerNames.name")} value={name} onChange={handleNameChanged} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <EnumSelectInput
                    label={t("assets.headerNames.category")}
                    options={Object.keys(RiskCategory)}
                    value={category}
                    onChange={handleCategoryChanged}
                />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
                <ProbabilityInput
                    label={t("assets.headerNames.probability")}
                    value={probability}
                    onChange={handleProbabilityChanged}
                />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
                <SeverityInput
                    label={t("assets.headerNames.severity")}
                    value={severity}
                    onChange={handleSeverityChanged}
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    label={t("assets.headerNames.description")}
                    rows={10}
                    value={description}
                    onChange={handleDescriptionChanged}
                />
            </Grid>
        </Grid>
    );
}
