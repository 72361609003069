import * as React from "react";
import Box from "@mui/material/Box";
import {alpha, AppBar, IconButton, InputBase, styled, Toolbar, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import {useLayoutContext} from "../context/layout/LayoutContext";
import {useCallback} from "react";
import {UserMenu} from "./UserMenu";
import {useAuthContext} from "../context/auth/AuthContext";
import {useLanguageContext} from "../context/language/LanguageContext";
import {LanguageSwitcherMenu} from "./LanguageSwitcherMenu";
import {useNavigate} from "react-router-dom";
import {useTenantContext} from "../context/tenant/TenantContext";

interface TopNavProps {}

const Search = styled("div")(({theme}) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto"
    }
}));

const SearchIconWrapper = styled("div")(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        [theme.breakpoints.up("sm")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch"
            }
        }
    }
}));

export function TopNav(props: TopNavProps) {
    const {title, drawerNavOpen, setDrawerNavOpen} = useLayoutContext();
    const {auth} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const {t} = useLanguageContext();
    const navigate = useNavigate();

    const toggleNavDrawer = useCallback(() => {
        setDrawerNavOpen(!drawerNavOpen);
    }, [drawerNavOpen, setDrawerNavOpen]);

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
                <Toolbar>
                    {auth.isLoggedIn && (
                        <IconButton size="large" edge="start" color="inherit" sx={{mr: 2}} onClick={toggleNavDrawer}>
                            <MenuIcon />
                        </IconButton>
                    )}
                    <img onClick={() => navigate(`/tenants/${tenantUuid}`)}
                        src="/artiligence-logo-white.png"
                        alt="Artiligence"
                        style={{height: "24px", marginRight: "16px", cursor: "pointer"}}
                    />
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{flexGrow: 1, display: {xs: "none", sm: "block"}}}
                    >
                        {title}
                    </Typography>
                    {auth.isLoggedIn && (
                        <>
                            <Search>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder={t("search.placeholder")}
                                    inputProps={{"aria-label": t("search.singular")}}
                                />
                            </Search>
                            <Box p={2} />
                            <LanguageSwitcherMenu />
                            <UserMenu />
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
