import * as React from "react";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useEffect, useState} from "react";
import {defaultPage, IAsset, Page, PageChange} from "../../domain/types";
import {AssetsProvider} from "../../domain/AssetsProvider";
import Container from "@mui/material/Container";
import {Button, Grid} from "@mui/material";
import {gs} from "../../theme";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {APagingTable} from "../../base/table/APagingTable";
import {GridColDef} from "@mui/x-data-grid";
import {useNavigate} from "react-router-dom";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface AssetsListProps {}

export function AssetsList(props: AssetsListProps) {
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();

    const [pageChange, setPageChange] = useState<PageChange>(defaultPage);
    const [assets, setAssets] = useState<Page<IAsset>>();
    const [selected, setSelected] = useState<string[]>([]);

    useEffect(() => {
        const assetsProvider = new AssetsProvider(api);
        if (api.auth) {
            assetsProvider.list(tenantUuid, pageChange).then(setAssets);
        }
    }, [api, tenantUuid, pageChange]);

    const {t} = useLanguageContext();
    const columns: GridColDef[] = [
        {field: "name", headerName: t("assets.headerNames.name"), flex: 1},
        {field: "category", headerName: t("assets.headerNames.category"), flex: 1},
        {field: "type", headerName: t("assets.headerNames.type"), flex: 1},
        {field: "vendor", headerName: t("assets.headerNames.vendor"), flex: 1}
    ];
    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("assets.plural")}
                        crumbs={[{label: t("assets.plural"), href: `/tenants/${tenantUuid}/assets`}]}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => navigate(`/tenants/${tenantUuid}/assets/add`)}
                        >
                            {t("assets.addAsset")}
                        </Button>
                    </AHeaderWithBreadcrumbs>
                </Grid>
                {assets && (
                    <Grid item xs={12}>
                        <APagingTable
                            page={assets}
                            columns={columns}
                            onPageChanged={setPageChange}
                            onClick={(asset: IAsset) => navigate(`/tenants/${tenantUuid}/assets/${asset.uuid}`)}
                            onSelectionChanged={setSelected}
                        />
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
