import * as React from "react";
import Container from "@mui/material/Container";
import {useEffect, useState} from "react";
import {KiToolsProvider} from "../../domain/KiToolsProvider";
import {useAuthContext} from "../../context/auth/AuthContext";
import {defaultPage, IKiTool, Page, PageChange} from "../../domain/types";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {Button, Grid} from "@mui/material";
import {APagingTable} from "../../base/table/APagingTable";
import {gs} from "../../theme";
import {GridColDef} from "@mui/x-data-grid";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {useNavigate} from "react-router-dom";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface ToolsListProps {}

export function ToolsList(props: ToolsListProps) {
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const {t} = useLanguageContext();

    const [pageChange, setPageChange] = useState<PageChange>(defaultPage);
    const [kiTools, setKiTools] = useState<Page<IKiTool>>();
    const [selected, setSelected] = useState<string[]>([]);

    const columns: GridColDef[] = [
        {field: "name", headerName: t("tools.headers.name"), flex: 1},
        {field: "category", headerName: t("tools.headers.category"), flex: 1},
        {field: "type", headerName: t("tools.headers.type"), flex: 1},
        {field: "vendor", headerName: t("tools.headers.vendor"), flex: 1}
    ];

    useEffect(() => {
        const kiToolsProvider = new KiToolsProvider(api);
        if (api.auth) {
            kiToolsProvider.list(tenantUuid, pageChange).then(setKiTools);
        }
    }, [api, tenantUuid, pageChange]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("tools.plural")}
                        crumbs={[
                            {
                                label: t("tools.plural"),
                                href: `/tenants/${tenantUuid}/tools`
                            }
                        ]}
                    >
                        {selected.length == 1 && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => navigate(`/tenants/${tenantUuid}/assets/add?toolUuid=${selected[0]}`)}
                            >
                                {t("tools.assets.create")}
                            </Button>
                        )}
                    </AHeaderWithBreadcrumbs>
                </Grid>
                {kiTools && (
                    <Grid item xs={12}>
                        <APagingTable
                            page={kiTools}
                            columns={columns}
                            onPageChanged={setPageChange}
                            onClick={(tool) => navigate(`/tenants/${tenantUuid}/tools/${tool.uuid}`)}
                            onSelectionChanged={setSelected}
                        />
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
