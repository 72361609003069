import * as React from "react";
import {Page, PageChange} from "../../domain/types";
import {DataGrid, GridColDef, GridPaginationModel, GridRowParams, GridRowSelectionModel} from "@mui/x-data-grid";
import {useCallback} from "react";
import {Paper} from "@mui/material";
import {NoRowsOverlay} from "./NoRowsOverlay";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface APagingTableProps<T> {
    page: Page<T>;
    onPageChanged: (pageChange: PageChange) => void;
    columns: GridColDef[];
    onClick?: (row: T) => void;
    onSelectionChanged?: (selected: string[]) => void;
    noRowsLabel?: string;
    getRowId?: (row: T) => string;
}

export function APagingTable<T>(props: APagingTableProps<T>) {
    const {page, columns, onPageChanged, onClick, onSelectionChanged, noRowsLabel, getRowId} = props;

    const handlePaginationChanged = useCallback(
        (params: GridPaginationModel, page: Page<T>) => {
            onPageChanged(params);
        },
        [onPageChanged]
    );

    const handleOnClick = useCallback(
        (params: GridRowParams) => {
            if (onClick) {
                onClick(params.row as T);
            }
        },
        [onClick]
    );

    const handleSelectionChanged = useCallback(
        (model: GridRowSelectionModel) => {
            if (onSelectionChanged) {
                onSelectionChanged(model.map((row) => row.toString()));
            }
        },
        [onSelectionChanged]
    );

    const {t} = useLanguageContext();
    return (
        <Paper>
            <DataGrid
                rows={page.content}
                columns={columns}
                paginationMode="server"
                rowCount={page.totalElements}
                paginationModel={{
                    page: page.number,
                    pageSize: page.size
                }}
                onPaginationModelChange={(params) => handlePaginationChanged(params, page)}
                getRowId={(row) => {
                    if (getRowId) {
                        return getRowId(row);
                    } else {
                        return row.uuid;
                    }
                }}
                pageSizeOptions={[25, 50, 100]}
                checkboxSelection
                disableRowSelectionOnClick
                onRowClick={handleOnClick}
                onRowSelectionModelChange={(model) => handleSelectionChanged(model)}
                slots={{
                    noRowsOverlay: () => <NoRowsOverlay message={noRowsLabel || t("table.noRowsFound")} />
                }}
            />
        </Paper>
    );
}
