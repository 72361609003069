import {Grid} from "@mui/material";
import * as React from "react";
import {TextInput} from "../../../base/inputs/TextInput";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {gs} from "../../../theme";

interface StepNameInputProps {
    description: string;
    onDescriptionChanged: (name: string) => void;
}

export function StepDescriptionInput(props: StepNameInputProps) {
    const {description, onDescriptionChanged} = props;
    const {t} = useLanguageContext();
    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <TextInput label={t("description")} value={description} onChange={onDescriptionChanged} rows={6} />
            </Grid>
        </Grid>
    );
}
