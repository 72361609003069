import * as React from "react";
import {Chip} from "@mui/material";
import {green, orange, red} from "@mui/material/colors";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface SeverityDisplayProps {
    severity: number;
    trafficLight?: boolean;
}

export function SeverityDisplay(props: SeverityDisplayProps) {
    const {severity, trafficLight} = props;
    const {t} = useLanguageContext();

    let label = t("severity.severities.Medium");
    let backgroundColor = undefined;
    let color = undefined;
    switch (severity) {
        case 1:
            label = t("severity.severities.VeryLow");
            backgroundColor = green[600];
            color = "white";
            break;
        case 2:
            label = t("severity.severities.Low");
            backgroundColor = green[300];
            break;
        case 3:
            label = t("severity.severities.Medium");
            backgroundColor = orange[300];
            break;
        case 4:
            label = t("severity.severities.High");
            backgroundColor = red[300];
            break;
        case 5:
            label = t("severity.severities.VeryHigh");
            backgroundColor = red[600];
            color = "white";
            break;
    }

    if (trafficLight) {
        return <Chip label={severity} sx={{backgroundColor, color}} />;
    }

    return <Chip label={label} sx={{backgroundColor, color}} />;
}
