import {Chip} from "@mui/material";
import {green, orange, red} from "@mui/material/colors";
import * as React from "react";

interface PriorityDisplayProps {
    priority: number;
}

export function PriorityDisplay(props: PriorityDisplayProps) {
    const {priority} = props;

    let backgroundColor = undefined;
    let color = undefined;
    switch (priority) {
        case 1:
            backgroundColor = red[700];
            color = "white";
            break;
        case 2:
            backgroundColor = red[600];
            break;
        case 3:
            backgroundColor = orange[300];
            break;
        case 4:
            backgroundColor = green[500];
            break;
        case 5:
            backgroundColor = green[700];
            color = "white";
            break;
    }

    return <Chip label={priority} sx={{backgroundColor, color}} />;
}
