import {Autocomplete, Box, Grid, TextField, Typography} from "@mui/material";
import {debounce} from "@mui/material/utils";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {KiToolsProvider} from "../../domain/KiToolsProvider";
import {IKiTool} from "../../domain/types";

interface KiToolSearchInputProps {
    label: string;
    value?: IKiTool;
    onChange: (value: IKiTool) => void;
}

export function KiToolSearchInput(props: KiToolSearchInputProps) {
    const {label, value, onChange} = props;
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const {t} = useLanguageContext();

    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<readonly IKiTool[]>([]);

    const fetch = useMemo(
        () =>
            debounce((needle: string, callback: (results?: readonly IKiTool[]) => void) => {
                if (api.auth) {
                    const kiToolsProvider = new KiToolsProvider(api);
                    kiToolsProvider.search(tenantUuid, needle).then(callback);
                }
            }, 400),
        [api, tenantUuid]
    );

    useEffect(() => {
        let active = true;
        if (inputValue === "") {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch(inputValue, (results?: readonly IKiTool[]) => {
            if (active) {
                let newOptions: readonly IKiTool[] = [];
                if (value) {
                    newOptions = [value];
                }
                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            getOptionLabel={(option) => (typeof option === "string" ? option : option.name)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText={
                inputValue
                    ? `${t("tools.kiTool.noOptionsText.noKiTool")} "${inputValue}"`
                    : t("tools.kiTool.noOptionsText.startTyping")
            }
            onChange={(event: any, newValue: IKiTool | null) => {
                setOptions(newValue ? [newValue, ...options] : options);
                if (newValue) {
                    onChange(newValue);
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => <TextField {...params} label={label} fullWidth />}
            renderOption={(props, option) => {
                const matches = match(option.name, inputValue) || [];
                const parts = parse(option.name, matches);

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item sx={{width: "100%", wordWrap: "break-word"}}>
                                {parts.map((part, index) => (
                                    <Box
                                        key={index}
                                        component="span"
                                        sx={{fontWeight: part.highlight ? "bold" : "regular"}}
                                    >
                                        {part.text}
                                    </Box>
                                ))}
                                <Typography variant="body2" color="text.secondary">
                                    {option.vendor}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}
